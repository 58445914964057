const PlaceholderIcon = ({
	size = 'large',
	...props
}: {
	size?: 'small' | 'medium' | 'large'
}) => (
	<svg
		{...props}
		width={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		height={size === 'small' ? '16' : size === 'medium' ? '20' : '24'}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			minWidth: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
			minHeight: size === 'small' ? '16' : size === 'medium' ? '20' : '24',
		}}
		{...props}
	>
		<path
			d="M11.5 8C11.2239 8 11 8.22386 11 8.5L11 11L8.5 11C8.22386 11 8 11.2239 8 11.5L8 12.5C8 12.7761 8.22386 13 8.5 13L11 13L11 15.5C11 15.7761 11.2239 16 11.5 16L12.5 16C12.7761 16 13 15.7761 13 15.5L13 13L15.5 13C15.7761 13 16 12.7761 16 12.5V11.5C16 11.2239 15.7761 11 15.5 11L13 11L13 8.5C13 8.22386 12.7761 8 12.5 8H11.5Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 7V5H4V7H3ZM3 4V3H4V4H3ZM7 3H5V4H7V3ZM8 3V4H10V3H8ZM11 3V4H13V3H11ZM14 3V4H16V3H14ZM17 3V4H19V3H17ZM20 3V4L21 4V3H20ZM21 5H20V7H21V5ZM21 8H20V10H21V8ZM21 11H20V13H21V11ZM21 14H20V16H21V14ZM21 17H20V19H21V17ZM21 20H20V21H21V20ZM19 21V20H17V21H19ZM16 21V20H14V21H16ZM13 21V20H11V21H13ZM10 21V20H8V21H10ZM7 21V20H5V21H7ZM4 21V20H3V21H4ZM3 19H4V17H3V19ZM3 16H4V14H3V16ZM3 13H4V11H3V13ZM3 10H4V8H3V10Z"
			fill="currentColor"
		/>
	</svg>
)

export default PlaceholderIcon
